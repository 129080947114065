import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import shopFront from "./HeaderImgs/ShopFront.png";
import map from "./HeaderImgs/Map.png";
import phoneIcon from "./HeaderImgs/phone.png";
import mapIcon from "./HeaderImgs/mapIcon.png";
import clockIcon from "./HeaderImgs/clock.png";

const ContactButton = styled(Button)({
  color: "white",
  backgroundColor: "#04223D",
  padding: "5px 80px",
  fontSize: "1.5rem",
});

const ModalText = styled("p")({
  color: "white",
  textAlign: "justify",
  textAlignLast: "center",
  fontSize: "18px",
  lineHeight: "150%",
  fontFamily: "Archivo, sans-serif",
  fontOpticalSizing: "auto",
  fontWeight: "400",
  fontStyle: "normal",
  "@media (max-width: 600px)": {
    fontSize: ".9rem !important",
    margin: "0 !important",
  },
  "@media (max-width: 800px)": {
    fontSize: "1rem",
    margin: "0 10px",
  },
  "@media (max-width: 1000px)": {
    fontSize: "1.1rem",
    margin: "0 10px",
  },
});

const ModalIcon = styled("img")({
  width: "64px",
  height: "64px",
  margin: "20px 20px 0 40px",
  "@media (max-width: 600px)": {
    width: "40px !important",
    height: "40px !important",
    margin: "0 20px 0 0 !important",
  },
  "@media (max-width: 800px)": {
    width: "50px",
    height: "50px",
    margin: "0",
  },
});

const ModalLabel = styled("h2")({
  color: "white",
  fontFamily: "Bebas Neue",
  fontWeight: "400",
  fontStyle: "normal",
  fontSize: "1.5rem",
  "@media (max-width: 400px)": {
    fontSize: "1.2rem !important",
  },
  "@media (max-width: 600px)": {
    fontSize: "1.3rem",
  },
  "@media (max-width: 1000px)": {
    fontSize: "1.4rem",
  },
});

const ModalImg = styled("img")({
  width: "80%",
  borderRadius: "5px",
  "@media (max-width: 400px)": {
    width: "100% !important",
    margin: "10px 0 !important",
  },
  "@media (max-width: 600px)": {
    width: "90% !important",
    margin: "10px 0 !important",
  },
  "@media (max-width: 800px)": {
    width: "100%",
    margin: "0",
  },
  "@media (max-height: 779px)": {
    display: "none",
  },
});

const ModalBoxLt = styled(Box)({
  width: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  "@media (max-width: 600px)": {
    width: "100% !important",
    margin: "-20px 0 5px 0",
  },
  "@media (max-width: 800px)": {
    width: "30%",
    margin: "auto",
  },
});

const ModalBoxRt = styled(Box)({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  "@media (max-width: 600px)": {
    width: "100% !important",
    margin: "0 !important",
  },
  "@media (max-width: 800px)": {
    width: "70%",
    marginLeft: "20px",
  },
});

const ModalBoxSm = styled(Box)({
  display: "flex",
  flexDirection: "row",
  "@media (max-width: 600px)": {
    flexDirection: "row !important",
    justifyContent: "center",
    alignItems: "center",
    margin: "-10px 0 !important",
  },
  "@media (max-width: 800px)": {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "10px 0",
  },
});

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "70%",
  bgcolor: "#04223D",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 400px)": {
    width: "90vw !important",
    height: "90vh !important",
  },
  "@media (max-width: 600px)": {
    width: "90vw !important",
    height: "90vh !important",
    flexDirection: "column",
  },
  "@media (max-width: 800px)": {
    width: "90vw !important",
    height: "90vh !important",
  },
  "@media (max-width: 1000px)": {
    width: "90vw !important",
    height: "90vh !important",
  },
  "@media (max-width: 1200px)": {
    width: "80vw",
    height: "80vh",
  },
  "@media (max-width: 1400px)": {
    width: "80vw",
    height: "80vh",
  },
};

function Header() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      id="Home"
      sx={{
        display: "flex",
        marginBottom: "200px",
        overflowX: "hidden",
        "@media (max-width: 800px)": {
          height: "30%",
          // overflowY: "hidden",
          overflowX: "hidden",
          marginBottom: "100px",
        },
        "@media (max-width: 1000px)": {
          height: "30%",
          marginBottom: "200px",
        },
      }}
    >
      <Container
        maxWidth="false"
        disableGutters="true"
        sx={{
          backgroundImage: `url(${shopFront})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          textAlign: "center",
          height: "800px",
          marginBottom: "500px",
          position: "fixed",
          zIndex: "-30",
          // "@media (max-width: 400px)": {
          //   backgroundSize: "250%"
          // },
          "@media (max-width: 800px)": {
            // height: "50%",
            overflowX: "hidden",
            width: "100vw",

            // height: "400px",
            // overflowY: "hidden",
          },
        }}
      ></Container>
      <Container>
        <Typography
          sx={{
            margin: "8% 1% 3%",
            wordSpacing: ".25rem",
            color: "white",
            lineHeight: "3.5rem",
            fontSize: "1.1rem",
            "@media (max-width: 800px)": {
              fontSize: "1rem",
              lineHeight: "2.5rem",
              marginTop: "3rem",
            },
          }}
        >
          <h1
            style={{
              textShadow:
                ".5px .5px 0 #000, -.5px .5px 0 #000, -.5px -.5px 0 #000, .5px -.5px 0 #000",
            }}
          >
            Welcome to Tank Tex LLC, a leader in trailer services. Our team of
            experts is dedicated to providing the highest quality repairs and
            maintenance for your tank and trailer. Let us help keep your tank
            truck running smoothly and efficiently.
          </h1>
        </Typography>
        <ContactButton
          onClick={handleOpen}
          sx={{
            color: "white",
            backgroundColor: "#04223D",
            padding: "5px 80px",
            fontSize: "1.8rem",
            marginBottom: "80px",
            "@media (max-width: 800px)": {
              fontSize: "1.5rem",
              marginBottom: "0",
            },
            ":hover": {
              bgcolor: "primary.main",
            },
          }}
        >
          CONTACT US
        </ContactButton>
      </Container>

      {/* ********************
            CONTACT MODAL 
      ******************** */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalBoxLt>
            <ModalBoxSm>
              <ModalIcon src={phoneIcon} alt="phone icon" />
              <ModalLabel>
                CALL: <br />
                Tel: 832-289-9512
              </ModalLabel>
            </ModalBoxSm>
            <ModalBoxSm>
              <ModalIcon src={mapIcon} alt="map icon" />
              <ModalLabel>
                VISIT: <br />
                2514 Anders Ln <br />
                Kemah, Texas 77565
              </ModalLabel>
            </ModalBoxSm>
            <ModalBoxSm>
              <ModalIcon src={clockIcon} alt="clock icon" />
              <ModalLabel>
                HOURS: <br />
                Mon - Fri: 6am - 4pm
              </ModalLabel>
            </ModalBoxSm>
          </ModalBoxLt>
          <ModalBoxRt>
            <ModalText id="modal-modal-description" sx={{ mt: 2 }}>
              Tank Tex LLC is a reliable and experienced tank truck repair
              service provider for the region. Our team consists of highly
              skilled technicians with the latest tools and equipment to provide
              top-notch services. We specialize in welding and fabrication, skin
              repair, trailer cladding, and road services. Contact us today to
              get more information!
            </ModalText>
            <ModalImg src={map} alt="R Stamp Certificate" />
            <br />
            <a
              href="https://www.google.com/maps/place/2514+Anders+Ln,+Kemah,+TX+77565/@29.5210685,-95.026975,17z/data=!3m1!4b1!4m6!3m5!1s0x863f62da0c2dab21:0x20620dc8fbe20e74!8m2!3d29.5210685!4d-95.0244001!16s%2Fg%2F11cppx84_r?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#0A3E6E",
                  width: "200px",
                  height: "50px",
                  fontSize: "1.5rem",
                  ":hover": {
                    bgcolor: "primary.main",
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "1.2rem",
                    width: "150px",
                    height: "40px",
                  },
                  "@media (max-height: 779px)": {
                    marginTop: "30px",
                  },
                }}
              >
                Go To Map
              </Button>
            </a>
          </ModalBoxRt>
        </Box>
      </Modal>
    </Box>
  );
}

export default Header;
