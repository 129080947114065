import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import RStampIcon from "./AboutIcons/r-stamp-logo_white.png";
import DollarBillIcon from "./AboutIcons/dollar-bill.png";
import LightBulbIcon from "./AboutIcons/light-bulb.png";
import EmployeeIcon from "./AboutIcons/employee.png";

import RStampCert from "./AboutIcons/RStampCert.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "rgba(0, 0, 0, .5)",
  // border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  "@media (max-width: 400px)": {
    height: "auto",
    width: "auto",
    top: "35%",
    left: "34.5%",
  },
  "@media (max-width: 600px)": {
    height: "auto",
    width: "auto",
    // top: "50%",
    // left: "34.5%",
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(4),
  margin: "1% 10%",
  // width: "80%",
  height: "450px",
  boxShadow: "none",
  textAlign: "justify",
  textAlignLast: "center",
  color: "white",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "150%",
  "@media (max-width: 600px)": {
    margin: "10px 40px !important",
    padding: "0",
    height: "auto",
  },
  "@media (max-width: 800px)": {
    margin: "0 40px 20px",
    padding: "0",
    height: "auto",
  },
  "@media (max-width: 1000px)": {
    margin: "0 80px 20px",
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const Title = styled("h1")({
  fontSize: "3rem",
  margin: "0 0 10px",
  "@media (max-width: 600px)": {
    fontSize: "2.5rem",
    margin: "0 0 10px !important"
  },
});

const SubTitle = styled("p")({
  fontSize: "1.2rem",
  margin: "0 200px 50px",
  textAlign: "justify",
  textAlignLast: "center",
  "@media (max-width: 600px)": {
    margin: "0 0 30px !important",
    fontSize: "1.1rem",
  },
  "@media (max-width: 800px)": {
    margin: "0 0 50px !important",
  }
});

const Icon = styled("div")({
  width: "auto",
  height: "auto",
  margin: "0 auto",
});

const CardTitle = styled("h3")({
  margin: "30px 0 10px",
  fontSize: "1.5rem",
  textAlign: "center"
});

const CertButton = styled(Button)({
  color: "white",
  backgroundColor: "#04223D",
  padding: "2px 40px",
});

function About() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box id="About">
      <Container
        maxWidth="false"
        disableGutters="true"
        style={{
          backgroundColor: "#0A3E6E",
          color: "white",
          padding: "60px 0 60px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            // margin: "0px 200px 0px",
            "@media (max-width: 600px)": {
              margin: "10px 20px !important",
            },
            "@media (max-width: 800px)": {
              margin: "10px 60px !important",
            },
            "@media (max-width: 1000px)": {
              margin: "5%",
            },
            "@media (max-width: 1200px)": {
              margin: "0 100px",
            },
          }}
        >
          <Title>WE OFFER COMPREHENSIVE REPAIR SERVICES</Title>
          <SubTitle>
            Tank Tex is your trusted destination for top-tier tank preparation,
            repair, and DOT services with a specialization in welding and
            fabrication. We pride ourselves on our comprehensive range of
            services. From welding expertise to fabrication finesse, we are
            equipped to handle many tasks, including repairing, cladding, and
            replacing various components of trailers. Whether patching skin
            imperfections or conducting intricate repairs, our skilled
            technicians are adept at addressing any challenge with precision and
            efficiency.
          </SubTitle>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            margin: "0px 14% 0px",
            "@media (max-width: 600px)": {
              margin: "0px !important",
              overflowX: "hidden"
            },
            "@media (max-width: 800px)": {
              margin: "0px !important",
              overflowX: "hidden"
            },
            "@media (max-width: 1000px)": {
              margin: "0 60px",
              overflowX: "hidden"
            },
            "@media (max-width: 1200px)": {
              margin: "0 60px",
              overflowX: "hidden"
            },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            columns={{ xs: 1, sm: 1, md: 12, lg: 12, xl: 12 }}
          >
            <Grid item xs={6}>
              <Item>
                <Icon>
                  <img
                    width="128px"
                    height="128px"
                    src={RStampIcon}
                    alt="R Stamp Certificate Icon"
                  />
                </Icon>
                <CardTitle>R STAMP CERTIFIED</CardTitle>
                <p>
                  Our facility boasts an R stamp certification, ensuring
                  unparalleled quality and compliance in all our welding
                  endeavors.
                </p>
                <CertButton
                  onClick={handleOpen}
                  sx={{
                    fontSize: "1.3rem",
                    ":hover": {
                      bgcolor: "primary.main",
                      color: "white",
                    },
                  }}
                >
                  VIEW CERTIFICATE
                </CertButton>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Icon>
                  <img src={DollarBillIcon} alt="Dollar Bill Icon" />
                </Icon>
                <CardTitle>COMPETITIVE PRICES</CardTitle>
                <p>
                  At Tank Tex LLC, we believe that high-quality tank truck
                  repair services should be affordable. That's why we offer
                  competitive prices to ensure that you get the best value for
                  your money.
                </p>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Icon>
                  <img src={LightBulbIcon} alt="Lightbulb Icon" />
                </Icon>
                <CardTitle>ADVANCED TECH</CardTitle>
                <p>
                  We strive to improve our offerings and expand upon our
                  technological capabilities. Our expert team is passionate
                  about developing the most advanced tech on the market. Ready
                  to experience the future? Get in touch.
                </p>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Icon>
                  <img src={EmployeeIcon} alt="Employee Icon" />
                </Icon>
                <CardTitle>EXPERIENCED PROFESSIONALS</CardTitle>
                <p>
                  Our team of professionals has years of experience in working
                  with all types of tank trucks. We are dedicated to providing
                  the highest level of service and expertise to ensure that your
                  tank truck is always in top condition.
                </p>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* R STAMP CERT MODAL */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontFamily="Bebas Neue"
            fontWeight="400"
            fontStyle="normal"
            fontSize="2rem"
            color="white"
          >
            R Stamp Certificate
          </Typography>
          <img src={RStampCert} alt="R Stamp Certificate" width="100%" />
        </Box>
      </Modal>
    </Box>
  );
}

export default About;
