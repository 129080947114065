import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";


// import trucks from "./Trucks.jpg";

function SectionBreak(props) {
  return (
    <Box sx={{ display: "flex" }} style={{
        marginBottom: "0"
      }}>
      <Container maxWidth="false" disableGutters="true"
        style={{
            // backgroundColor: "green",
          backgroundImage: `url(${props.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "250px",
          "@media (max-width: 400px)": {
            height: "150px"
          },
          width: "100%",
          color: "white",
          backgroundAttachment: "fixed"
          // position: "fixed",
          // top: "665px",
        //   marginBottom: "500px",
          // zIndex: "-20"
        }}
      >
      </Container>
    </Box>
  );
}

export default SectionBreak;
