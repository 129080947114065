import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Photo1 from "./GalleryPhotos/IMG_6494.jpg";
import Photo2 from "./GalleryPhotos/IMG_6495.jpg";
import Photo3 from "./GalleryPhotos/IMG_6496.jpg";
import Photo4 from "./GalleryPhotos/IMG_6497.jpg";
import Photo5 from "./GalleryPhotos/IMG_7023.jpg";
import Photo6 from "./GalleryPhotos/IMG_7024.jpg";
import Photo7 from "./GalleryPhotos/IMG_7366.jpg";
import Photo8 from "./GalleryPhotos/IMG_7367.jpg";
import Photo9 from "./GalleryPhotos/IMG_7368.jpg";
import Photo10 from "./GalleryPhotos/IMG_7369.jpg";
import Photo11 from "./GalleryPhotos/IMG_7518.jpg";
import Photo12 from "./GalleryPhotos/IMG_7519.jpg";

const Title = styled("h1")({
  fontSize: "3rem",
  margin: "0 0 10px",
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  width: "310px",
  height: "310px",
  margin: "auto",
  ":hover": {
    "> .ImgTitle": {
      opactiy: "1",
    },
  },
  "@media (max-width: 600px)": {
    width: "300px",
    height: "300px",
    padding: "0",
  },
  "@media (max-width: 800px)": {
    width: "300px",
    height: "300px",
    padding: "0",
  },
}));


const ImgTitle = styled("h3")({
  color: "white",
  fontSize: "2rem",
  opacity: "0",
});

const ImgButton = styled(Button)({
  width: "150px",
  height: "40px",
  backgroundColor: "#0A3E6E",
  color: "white",
  fontSize: "10px",
  marginTop: "100px",
  opacity: "0",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "rgba(0, 0, 0, .5)",
  // border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

function WorkGallery() {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container
      id="Work"
      maxWidth="false"
      disableGutters="true"
      style={{
        textAlign: "center",
        backgroundColor: "#04223D",
        color: "white",
        margin: "0 auto",
        padding: "30px 0",
        flexDirection: "column",
        height: "auto",
        alignContent: "center",
        alignItems: "center",
        width: "100vw",
      }}
      sx={{ display: "flex" }}
    >
      <Box sx={{ flexGrow: 1, width: "100vw" }}>
        <Title>OUR WORK</Title>
      </Box>
      <Box sx={{ flexGrow: 1, width: "100vw" }}>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 4, md: 9, lg: 9, xl: 12}}
          sx={{
            padding: "50px 200px",
            width: "100%",
            "@media (max-width: 600px)": {
              margin: "0",
              padding: "0",
            },
            "@media (max-width: 800px)": {
              margin: "0",
              padding: "0",
            },
            "@media (max-width: 1200px)": {
              margin: "10px",
              padding: "0",
              width: "auto"
            },
            "@media (max-width: 1400px)": {
              margin: "10px",
              padding: "0",
              width: "auto"
            },
            "@media (max-width: 1600px)": {
              margin: "10px",
              padding: "0",
              width: "auto"
            },
          }}
        >
          {photos.map((item) => (
            <Grid item xs={6} sm={6} md={3} key={item.img} width="100%">
              <Item
                style={{ backgroundImage: "url(" + item.img + ")" }}
                sx={{
                  ":hover": {
                    backgroundColor: "rgba(0,0,0,0.6)",
                    backgroundBlendMode: "darken",
                    "> .ImgTitle": {
                      opacity: "1",
                    },
                    "> .ImgButton": {
                      opacity: "1",
                    },
                  },
                }}
              >
                <ImgTitle className="ImgTitle">{item.title}</ImgTitle>
                <ImgButton
                  onClick={() => {
                    handleOpen();
                    setModalData(item);
                    console.log(item);
                  }}
                  className="ImgButton"
                  sx={{
                    fontSize: "1.3rem",
                    ":hover": {
                      bgcolor: "primary.main",
                      color: "white",
                    },
                  }}
                >
                  View Full Image
                </ImgButton>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* FULL SIZE IMAGE MODAL */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontFamily="Bebas Neue"
            fontWeight="400"
            fontStyle="normal"
            fontSize="2rem"
            color="white"
          >
            {modalData.title}
          </Typography>
          <img src={modalData.img} alt={modalData.title} width="100%" />
        </Box>
      </Modal>
    </Container>
  );
}

const photos = [
  {
    img: `${Photo1}`,
    title: "Wreck",
  },
  {
    img: `${Photo2}`,
    title: "Wreck",
  },
  {
    img: `${Photo3}`,
    title: "Wreck",
  },
  {
    img: `${Photo4}`,
    title: "Wreck",
  },
  {
    img: `${Photo5}`,
    title: "Repair",
  },
  {
    img: `${Photo6}`,
    title: "Repair",
  },
  {
    img: `${Photo7}`,
    title: "Custom Chassis",
  },
  {
    img: `${Photo8}`,
    title: "Custom Chassis",
  },
  {
    img: `${Photo9}`,
    title: "Custom Chassis",
  },
  {
    img: `${Photo10}`,
    title: "Custom Chassis",
  },
  {
    img: `${Photo11}`,
    title: "Upper Couple Jack",
  },
  {
    img: `${Photo12}`,
    title: "Upper Couple Jack",
  },
];

export default WorkGallery;
