import './App.css';

import NavBar from "./Components/NavBar/NavBar";
import Header from "./Components/Header/Header";
import Services from "./Components/TankServices/TankServices";
import SectionBreak from './Components/SectionBreak/SectionBreak';
import About from './Components/About/About';
import WorkGallery from './Components/WorkGallery/WorkGallery';
import Footer from './Components/Footer/Footer';


import Section1Image from "./Trucks.jpg";
import Section2Image from "./ShopFlipped.png"

function App() {
  return (
    <div className="App" overflowX="hidden">
     <NavBar />
     <Header />
     <Services />
     <SectionBreak image={Section1Image}/>
     <About />
     <SectionBreak image={Section2Image}/>
     <WorkGallery />
     <Footer />
    </div>
  );
}

export default App;
