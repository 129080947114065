import * as React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";

import logo from "./TANK TEX LOGO.png";
import Phone from "./FooterIcons/phone.png";
import Map from "./FooterIcons/map.png";
import Clock from "./FooterIcons/clock.png";

const FooterBox = styled(Box)({
  color: "black",
  backgroundColor: "white",
  width: "100%",
  height: "70px",
  overflowX: "hidden",
  overflowY: "hidden",
  "@media (max-width: 400px)": {
    padding: "0 !important",
    // height: "140px !important",
    width: "100vw",
    flexDirection: "column !important",
    alignItems: "center",
    // overflowX: "hidden"
  },
  "@media (max-width: 600px)": {
    paddingBottom: "10px",
    height: "140px !important",
    width: "100vw",
    flexDirection: "column !important",
    alignItems: "center",
  },
  "@media (max-width: 800px)": {
    padding: "15px 5px",
    height: "70px",
    width: "100vw",
    flexDirection: "row",
    alignItems: "center",
  },
});

const LogoBox = styled(Box)({
  height: "60px",
  // margin: "10px",
  padding: "5px 5px 5px 20px",
  "@media (max-width: 400px)": {
    height: "60px !important",
    padding: "0 !important",
    margin: "0 !important",
    // height: "60px",
    // width: "90px",
  },
  "@media (max-width: 600px)": {
    height: "25px",
    padding: "0 0 50px !important",
    margin: "0",
    // height: "60px",
    // width: "90px",
  },
  "@media (max-width: 800px)": {
    height: "25px",
    padding: "0 !important",
    margin: "0 !important",
    // height: "60px",
    // width: "90px",
  },
});

const IconLabel = styled("h3")({
  display: "flex",
  fontSize: "1.5rem",
  margin: "5px 30px 5px 8px",
  width: "300px",
  height: "auto",
  "@media (max-width: 600px)": {
    margin: "0 !important",
    fontSize: "1rem !important",
    height: "40px !important",
    width: "110px !important"
  },
  "@media (max-width: 800px)": {
    margin: "auto 0px auto 0px !important",
    fontSize: "1.1rem",
    width: "auto",
    // overflowX: "hidden",
    alignSelf: "center",
    justifySelf: "center",
  },
  "@media (max-width: 1000px)": {
    margin: "auto 10px auto 5px",
    fontSize: "1.3rem",
    alignSelf: "center",
    justifySelf: "center",
    width: "150px",
  },
});

const LogoImage = styled("img")({
  height: "60px",
  "@media (max-width: 800px)": {
    padding: "5px 0 !important",
  },
});

const IconImage = styled("img")({
  height: "45px",
  "@media (max-width: 600px)": {
    width: "30px !important",
    height: "30px !important",
    margin: "0 auto !important"
  },
  "@media (max-width: 800px)": {
    width: "auto",
    height: "35px",
    margin: "0 3px !important"
  },
});

const IconBox = styled(Box)({
  display: "flex",
  height: "60px",
  width: "250px",
  "@media (max-width: 400px)": {
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    width: "10% !important",
    margin: "0 auto",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    margin: "0 auto",
  },
  "@media (max-width: 800px)": {
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    width: "160px !important",
    margin: "5px auto",
  },
  "@media (max-width: 1000px)": {
    flexDirection: "row",
    alignItems: "center",
    width: "200px",
    margin: "5px auto",
  },
  "@media (max-width: 2000px)": {
    flexDirection: "row",
    alignItems: "center",
    // width: "30%",
    // margin: "5px auto",
  },
});

function Footer() {
  return (
    <FooterBox
      sx={{
        display: "flex",
        "@media (max-width: 2000px)": {
          // padding: "auto",
          justifyContent: "space-between"
        },
      }}
    >
      <LogoBox sx={{ display: { xs: "flex" } }}>
        <LogoImage src={logo} alt="Tank Tex LLC Logo" />
      </LogoBox>
      <Box sx={{ display: "flex", maxWidth: "100vw", margin: "auto 0" }} >
        <IconBox sx={{ display: "flex", justifyContent: "center" }}>
          <IconImage src={Phone} alt="Phone Icon" />
          <IconLabel>832-289-9512</IconLabel>
        </IconBox>
        <IconBox sx={{ display: "flex" }}>
          <IconImage src={Map} alt="Map Icon" />
          <IconLabel>2514 Anders Ln Kemah, Texas 77565</IconLabel>
        </IconBox>
        <IconBox sx={{ display: "flex" }}>
          <IconImage src={Clock} alt="Clock Icon" />
          <IconLabel>MON - FRI: 6AM - 4PM</IconLabel>
        </IconBox>
      </Box>
    </FooterBox>
  );
}

export default Footer;
