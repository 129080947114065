import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//ICON IMPORT
import WeldingIcon from "./ServicesIcons/welding.png";
import ChecklistIcon from "./ServicesIcons/checklist.png";
import CaulkIcon from "./ServicesIcons/caulk-gun.png";
import EngineIcon from "./ServicesIcons/engine.png";
import TruckIcon from "./ServicesIcons/truck.png";
import SprayPaintIcon from "./ServicesIcons/spray-paint.png";

const Title = styled("h1")({
  fontSize: "3rem",
  margin: "0 0 10px",
  "@media (max-width: 400px)": {
    fontSize: "2.5rem",
    margin: "20px 0 0"
  },
});

const SubTitle = styled("p")({
  fontSize: "1.2rem",
  margin: "0 200px 50px",
  textAlign: "justify",
    textAlignLast: "center",
  "@media (max-width: 400px)": {
    margin: "0 20px 30px !important"
  },
  "@media (max-width: 600px)": {
    margin: "0 20px 30px !important",
    fontSize: "1.1rem",
  },
  "@media (max-width: 800px)": {
    textAlign: "justify",
    textAlignLast: "center",
    margin: "20px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  boxShadow: "none",
  textAlign: "justify",
  textAlignLast: "center",
  color: "white",
  fontWeight: "400",
  fontSize: "1.2rem",
  lineHeight: "150%",
  "@media (max-width: 400px)": {
    margin: "0 40px"
  },
  "@media (max-width: 600px)": {
    fontSize: "1.1rem",
    margin: "0 0px"
  },
}));

const Icon = styled("div")({
  width: "64px",
  margin: "0 auto",
});

const CardTitle = styled("h3")({
  margin: "10px 0",
  fontSize: "1.5rem",
});

function TankServices() {
  return (
    <Box id="Services">
      <Container
        maxWidth="false"
        disableGutters="true"
        sx={{
          backgroundColor: "#04223D",
          color: "white",
          padding: "60px 0",
          "@media (max-width: 400px)": {
            padding: "20px 0 !important",
            alignItems: "center",
            width: "100%",
          },
          "@media (max-width: 600px)": {
            padding: "40px 0 !important",
            alignItems: "center",
            width: "100%",
          },
          "@media (max-width: 800px)": {
            padding: "60px 0",
            alignItems: "center",
            width: "100%",
            // padding: "0 30px"
          },
          "@media (max-width: 1000px)": {
            padding: "60px 0",
            alignItems: "center",
            width: "100%",
            // padding: "0 30px"
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            margin: "0px 200px 0px",
            "@media (max-width: 400px)": {
              margin: "0px !important",
            },
            "@media (max-width: 600px)": {
              margin: "0px !important",
            },
            "@media (max-width: 1000px)": {
              margin: "20px",
            },
            "@media (max-width: 1400px)": {
              margin: "40px",
            },
          }}
        >
          <Title>OUR SERVICES</Title>
          <SubTitle>
            At Tank Tex LLC. we understand the importance of keeping your tank
            truck in good condition and specialize in providing top-notch repair
            services. Our team of experienced, dedicated mechanics is committed
            to delivering quality services that exceed expectations.{" "}
          </SubTitle>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            margin: "0px 200px 0px",
            "@media (max-width: 400px)": {
              margin: "0px !important",
            },
            "@media (max-width: 600px)": {
              margin: "40px !important",
            },
            "@media (max-width: 1000px)": {
              margin: "40px",
            },
            "@media (max-width: 1200px)": {
              margin: "40px",
            },
            "@media (max-width: 1400px)": {
              margin: "60px",
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: 8, md: 8, lg: 12, xl: 12}}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={WeldingIcon} alt="Welding Icon" />
                </Icon>
                <CardTitle>WELDING AND FABRICATION</CardTitle>
                <p>
                  We offer a range of tank truck maintenance services to ensure
                  your tank truck is always in good condition. Our team of
                  experts will work with you to develop a customized maintenance
                  plan that meets your specific needs. From routine oil changes
                  to brake inspections, we've got you covered.
                </p>
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={ChecklistIcon} alt="Checklist Icon" />
                </Icon>
                <CardTitle>DOT INSPECTIONS</CardTitle>
                <p>
                  DOT inspection services tailored specifically for tank trucks.
                  With a steadfast commitment to safety and compliance, Tank Tex
                  offers a comprehensive suite of inspection solutions designed
                  to keep your fleet operating at peak performance while meeting
                  all regulatory requirements.
                </p>
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={CaulkIcon} alt="Caulk Icon" />
                </Icon>
                <CardTitle>TRAILER CLADDING</CardTitle>
                <p>
                  Discover the latest advancements in trailer cladding
                  technology designed to withstand the harshest of conditions on
                  the road. From weather-resistant materials to impact-resistant
                  coatings, we provide in-depth insights into the various
                  options available to fortify your trailers against wear and
                  tear.
                </p>
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={EngineIcon} alt="Check Engine Icon" />
                </Icon>
                <CardTitle>ROAD SERVICES</CardTitle>
                <p>
                  We know that breakdowns can happen at any time, which is why
                  we offer emergency repair services. Our team of experienced
                  mechanics is always on standby to help you get back on the
                  road as quickly as possible. With our fast and reliable
                  service, you can rest assured that your tank truck is in good
                  hands.
                </p>
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={TruckIcon} alt="Tank Truck Icon" />
                </Icon>
                <CardTitle>SKIN REPAIR</CardTitle>
                <p>
                  Innovative methods and premium products tailored to rectify
                  skin damage on tank trucks, guaranteeing that your vehicles
                  maintain optimal condition for the safe and efficient
                  transportation of goods. We offer a wide range of repair
                  techniques, from minor dent patching to restoring coroded
                  areas, all specifically tailored to meet the unique needs of
                  tank truck maintenance.
                </p>
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Item>
                <Icon>
                  <img src={SprayPaintIcon} alt="Spray Paint Icon" />
                </Icon>
                <CardTitle sx={{textAlign: "center"}}>REFURBISHMENT | SANDBLASTING | PAINT</CardTitle>
                <p>
                  Our comprehensive services encompass the entire spectrum of
                  tank truck maintenance, from surface preparation through to
                  the final coat of paint. Utilizing advanced sandblasting
                  techniques, we meticulously strip away old coatings, rust, and
                  contaminants, restoring surfaces to their prime condition.
                </p>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default TankServices;
